import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewFooter from '../components/newFooter'
import { Link, useNavigate, useLocation } from "react-router-dom";

const FeatureTask = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">

            <Header />
            <div className='features-page'>
                <div className="header-container">
                    <div className="">
                        <div className='breadcrumb-container'>
                            <Link to="/">Features  </Link>
                            <i className="fas fa-chevron-right" style={{
                                fontSize: '12px',
                            }}></i>{" "}
                            <spna>  Task</spna>
                        </div>
                        <h1 className="">Stay Organized, Stay Productive</h1>
                        <p className="mb-4">
                        Uniteam takes the stress out of managing tasks by bringing all your work into one intuitive platform, so you can stay focused and get more done
                        </p>
                        <div className='features-page-btn-container'>
                        <Link to="https://app.uniteam.ai/register"  className="btn  get-started">
                                Get Started
                            </Link>
                            <Link to="/book-a-demo"  className="btn book-demo">
                                Book a Demo
                            </Link>
                        </div>

                    </div>
                    
                </div>
                <div className='help-you'>
                        <p>Here's how we can help you</p>
                    </div>
                {/* Features Section */}
                <section className="innercontainer feature-container">

                    <div className="row">
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>All Your Tasks in One Place</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Prioritize with Clarity</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Automatic Reminders</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Team Task Tracking</span>
                            </div>

                        </div>
                       
                    </div>

                </section>
                {/* Section: All Tasks in One View */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>All Your Tasks in One Place</h2>
                            <p>
                            Uniteam brings together personal to-dos, manager-assigned tasks, meeting action items, and project activities into a single, clutter-free view. Say goodbye to scattered notes and endless emails.

                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-all-talk-one-place.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Effortless Task Creation */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Prioritize with Clarity</h2>
                            <p>
                            Tasks are automatically organized into clear categories: "Overdue," "Today," "Tomorrow," and "This Week." You can also sort them by due date, priority, or team member, giving you a full view of what’s important.

                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-Priority-task.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Team Leader Advantages */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Automatic Reminders</h2>
                            <p>
                            Never miss a deadline with automated email reminders and push notifications for high-priority tasks. Keep on top of your work without feeling overwhelmed.

                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-automait-reminder.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Never Miss a Beat */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Team Task Tracking</h2>
                            <p>
                            As a manager, you can view your team's tasks in real-time, making it easier to track progress and address bottlenecks. A daily digest email keeps you updated on completed tasks, helping reduce the need for status meetings.

                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-team-task-tracking.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
              
                <section className="testimonial-section innercontainer mt-100">
                    {/* Company Logo */}
                    <div className="company-logo">
                        <img
                            src="/images/features/Amazon_logo.svg"
                            alt="Amazon Logo" 
                        />
                    </div>
                    {/* Testimonial Text */}
                    <div className="testimonial-text">
                        <p>
                        “Uniteam has revolutionized our task management process. The AI features
                            are incredibly helpful! Synthium has revolutionized our task management
                            process. The AI features are incredibly helpful!”
                        </p>
                    </div>
                    {/* Author Info */}
                    <div className="author-info">
                        {/* Author Image */}
                        <div className="author-img">
                            <img src="/images/features/sample-profile.png" alt="Jonathan Doe" />
                        </div>
                        {/* Author Details */}
                        <div className="author-details">
                            <h4>Jonathan Doe</h4>
                            <span>Design Director, Amazon</span>
                        </div>
                    </div>
                    </section>
            </div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default FeatureTask;