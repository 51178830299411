import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewFooter from '../components/newFooter'
import { Link, useNavigate, useLocation } from "react-router-dom";

const FeatureAIAssistant = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">

            <Header />
            <div className='features-page'>
                <div className="header-container">
                    <div className="">
                        <div className='breadcrumb-container'>
                            <Link to="/">Features  </Link>
                            <i className="fas fa-chevron-right" style={{
                                fontSize: '12px',
                            }}></i>{" "}
                            <spna>AI Assistant</spna>
                        </div>
                        <h1 className="">Delegate Tasks, Manage Projects, and More with Just a Conversation</h1>
                        <p className="mb-4">
                        Uniteam’s generative AI-powered assistant makes managing tasks and meetings effortless, saving you time and letting you focus on more important work
                        </p>
                        <div className='features-page-btn-container'>
                        <Link to="https://app.uniteam.ai/register" className="btn  get-started">
                                Get Started
                            </Link>
                            <Link to="/book-a-demo"     className="btn book-demo">
                                Book a Demo
                            </Link>
                        </div>

                    </div>
                 
                </div>

                <div className='help-you'>
                        <p>Here's how we can help you</p>
                    </div>
                {/* Features Section */}
                <section className="innercontainer feature-container">

                    <div className="row">
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Hands-Free Task Management</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Effortless Meeting Scheduling</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Smart Updates and Reports</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>On-the-Go Functionality</span>
                            </div>

                        </div>
                       
                    </div>

                </section>
                {/* Section: All Tasks in One View */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Hands-Free Task Management</h2>
                            <p>
                            Need to assign a task or check progress on a project? Simply ask the AI assistant. Whether you prefer text or voice, Uniteam’s AI will handle the details—no need to switch between screens or tools
                             </p>
                        </div>
                        <div className="col-md-12 text-center">
                            <img src="/images/features/feacture-hands-free-task.png "
                                className="feactures-page-images"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Effortless Task Creation */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Effortless Meeting Scheduling</h2>
                            <p>
                            Scheduling a meeting is as easy as asking. Use the AI assistant to set up meetings, send invites, or check the status of past meetings without lifting a finger
                            </p>
                        </div>
                        <div className="col-md-12 text-center">
                            <img src="/images/features/feacture-effortlesse-meeting.png"
                                className="feactures-page-images img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Team Leader Advantages */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Smart Updates and Reports</h2>
                            <p>
                            Stay updated without the hassle. Request task updates or team performance reports from the AI assistant whenever you need them. It’s like having an extra team member dedicated to keeping you organized
                           </p>
                        </div>
                        <div className="col-md-12 text-center">
                            <img src="/images/features/feacture-smart-updates.png"
                                className="feactures-page-images img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Never Miss a Beat */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>On-the-Go Functionality</h2>
                            <p>
                            The AI assistant is particularly useful when you're out and about. Use it to manage work on the move, delegate tasks, and make sure nothing slips through the cracks, whether you’re in the office or on the road.
                            </p>
                        </div>
                        <div className="col-md-12 text-center">
                            <img src="/images/features/feacture-on-the-go-functionality.png"
                                className="feactures-page-images img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
              
              
                <section className="testimonial-section innercontainer mt-100">
                    {/* Company Logo */}
                    <div className="company-logo">
                        <img
                            src="/images/features/Amazon_logo.svg"
                            alt="Amazon Logo" 
                        />
                    </div>
                    {/* Testimonial Text */}
                    <div className="testimonial-text">
                        <p>
                        “Uniteam has revolutionized our task management process. The AI features
                            are incredibly helpful! Synthium has revolutionized our task management
                            process. The AI features are incredibly helpful!”
                        </p>
                    </div>
                    {/* Author Info */}
                    <div className="author-info">
                        {/* Author Image */}
                        <div className="author-img">
                            <img src="/images/features/sample-profile.png" alt="Jonathan Doe" />
                        </div>
                        {/* Author Details */}
                        <div className="author-details">
                            <h4>Jonathan Doe</h4>
                            <span>Design Director, Amazon</span>
                        </div>
                    </div>
                    </section>
            </div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default FeatureAIAssistant;