import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewFooter from '../components/newFooter'
import { Link, useNavigate, useLocation } from "react-router-dom";


const FeatureEngagementAnalytics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">

            <Header />
            <div className='features-page'>
                <div className="header-container">
                    <div className="">
                        <div className='breadcrumb-container'>
                            <Link to="/">Features  </Link>
                            <i className="fas fa-chevron-right" style={{
                                fontSize: '12px',
                            }}></i>{" "}
                            <spna>Employee Engagement </spna>
                        </div>
                        <h1 className="">Employee Engagement – Keep Your Team<br/> Motivated and Productive</h1>
                        <p className="mb-4">
                        Uniteam provides the tools you need to keep your team engaged, happy, and productive – helping you retain top talent and build a positive work culture.
                        </p>
                        <div className='features-page-btn-container'>
                        <Link to="https://app.uniteam.ai/register"  className="btn  get-started">
                                Get Started
                            </Link>
                            <Link to="/book-a-demo" className="btn book-demo">
                                Book a Demo
                            </Link>
                        </div>

                    </div>
                  
                </div>
                <div className='help-you'>
                        <p>Here's how we can help you</p>
                    </div>
                {/* Features Section */}
                <section className="innercontainer feature-container">

                    <div className="row">
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Real-Time Engagement Tracking</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Actionable Engagement Insights</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>AI-Driven Employee Feedback</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Retention Strategies Made Simple</span>
                            </div>

                        </div>
                       
                    </div>

                </section>
                {/* Section: All Tasks in One View */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Real-Time Engagement Tracking</h2>
                            <p>
                            Keep a pulse on how your team is feeling with continuous engagement tracking. Uniteam tracks engagement levels and alerts managers when attention is needed.

                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-1.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Effortless Task Creation */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Actionable Engagement Insights</h2>
                            <p>
                            Get AI-powered insights that highlight potential areas of concern before they become serious issues. Uniteam helps managers take timely, effective action to boost morale and reduce the risk of attrition.

                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-2.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Team Leader Advantages */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>AI-Driven Employee Feedback</h2>
                            <p>
                            Gather employee feedback through easy, unobtrusive surveys delivered by Uniteam’s AI assistant. The feedback is summarized into meaningful reports, giving managers a clear view of team sentiment without sifting through data.

                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-3.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Never Miss a Beat */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Retention Strategies Made Simple</h2>
                            <p>
                            Uniteam’s engagement insights help you develop targeted retention strategies based on real-time data. Know what your team needs and take the right steps to keep your top talent engaged and productive.

                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feature-img-4.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
              
               
                <section className="testimonial-section innercontainer mt-100">
                    {/* Company Logo */}
                    <div className="company-logo">
                        <img
                            src="/images/features/Amazon_logo.svg"
                            alt="Amazon Logo" 
                        />
                    </div>
                    {/* Testimonial Text */}
                    <div className="testimonial-text">
                        <p>
                        “Uniteam has revolutionized our task management process. The AI features
                            are incredibly helpful! Synthium has revolutionized our task management
                            process. The AI features are incredibly helpful!”
                        </p>
                    </div>
                    {/* Author Info */}
                    <div className="author-info">
                        {/* Author Image */}
                        <div className="author-img">
                            <img src="/images/features/sample-profile.png" alt="Jonathan Doe" />
                        </div>
                        {/* Author Details */}
                        <div className="author-details">
                            <h4>Jonathan Doe</h4>
                            <span>Design Director, Amazon</span>
                        </div>
                    </div>
                    </section>
            </div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default FeatureEngagementAnalytics;